import React from "react"
import { isLoggedIn } from "../utils/auth"
import { navigate } from "@reach/router"

const NotFoundPage = () => {
  if (isLoggedIn()) {
    navigate("/app/home")
  } else {
    navigate("/Login")
  }
  return (
    <div style={{ height: "100vh" }}>
      <h1>No hay nada aqui</h1>
    </div>
  )
}

export default NotFoundPage
